export const START_A_BUSINESS_PERIOD_SUPPORTED_ENTITY_TYPES = [
  'Corporation',
  'Limited Liability Company',
]

export const CORPORATE_TRANSPARENCY_ACT_PHASE_SUPPORTED_ENTITY_TYPES = [
  'Association',
  'Attorney for Bar',
  'Benefit Corporation',
  'Benefit LLC',
  'Benefit Professional Corporation',
  'Close Corporation',
  'Close Limited Liability Company',
  'Close Professional Corporation',
  'Cooperative',
  'Cooperative Association',
  'Cooperative Association (For-Profit)',
  'Cooperative Association (Nonprofit)',
  'Corporation',
  'Corporation Sole',
  'DAO LLC',
  'Exempt Nonstock Corporation',
  'Farm Corporation',
  'Farm LLC',
  'Foundation',
  'General Partnership',
  'Limited Cooperative Association',
  'Limited Liability Company',
  'Limited Liability Limited Partnership',
  'Limited Liability Partnership',
  'Limited Partnership',
  'Low-Profit Limited Liability Company',
  'Massachusetts Trust',
  'Mutual Benefit Enterprise',
  'Mutual Benefit Nonprofit Corporation',
  'Nonprofit Corporation',
  'Nonprofit Limited Liability Company',
  'Other',
  'Personal Attorney',
  'Professional Corporation',
  'Professional Limited Liability Company',
  'Professional Limited Liability Limited Partnership',
  'Professional Limited Liability Partnership',
  'Professional Limited Partnership',
  'Public Benefit Corporation',
  'Public Benefit Nonprofit Corporation',
  'Registered Limited Liability Partnership',
  'Religious Nonprofit Corporation',
  'Series Limited Liability Company',
  'Series Professional Limited Liability Company',
  'Subsidiary Series LLC',
  'Sustainable Business Corporation',
  'Telehealthcare',
  'Trust',
]
