<template>
  <div id="root-flex-container">
    <connection-info ref="connection-info" />
    <top-navigation v-if="loaded" />
    <boi-faqs-banner v-if="loaded" />
    <items-requiring-attention-banner v-if="loaded" />
    <alert-manager />
    <phone-notification v-if="loaded" />
    <div id="main" class="d-flex w-100">
      <virtual-phone-sidebar v-if="needsVirtualPhoneSidebar" ref="virtualPhoneSidebar" />

      <div v-if="wrapInContentFlexContainer"
           id="content-flex-container"
           :class="contentFlexContainerClasses"
      >
        <b-container v-show="!routeLoading" ref="mainContent" class="main">
          <router-view />
        </b-container>
        <b-container v-show="routeLoading" class="nav-spinner-container">
          <ct-centered-spinner />
        </b-container>
        <live-help-now />
      </div>

      <router-view v-else />
    </div>
    <audio id="remoteAudio">
      <p>Your browser doesn't support HTML5 audio.</p>
    </audio>
  <div>
    <footer class="tos-footer-container">
      <p v-if="loaded && !isAccountWholesaler && !routeLoading" class="tos-footer">
        <a
          :href="websiteTosUrl"
          style="color: grey"
          target="_blank"
          aria-label="Terms of Service link"
        >Terms of Service
        </a>
      </p>
    </footer>
  </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { registerStagelineModules } from '@/common/modules/initialize'

  export default {
    name: 'App',
    components: {
      CtCenteredSpinner:              () => import('@/components/shared/CtCenteredSpinner'),
      VirtualPhoneSidebar:            () => import('@/components/VirtualPhones/VirtualPhoneSidebar.vue'),
      TopNavigation:                  () => import('@/components/navs/TopNavigation.vue'),
      BoiFaqsBanner:                  () => import('@/components/Vehicles/BoiFaqsBanner'),
      ItemsRequiringAttentionBanner:  () => import('@/components/ItemsRequiringAttentionBanner'),
      AlertManager:                   () => import('@/components/AlertManager.vue'),
      ConnectionInfo:                 () => import('@/components/shared/ConnectionInfo.vue'),
      PhoneNotification:              () => import('@/components/PhoneNotification.vue'),
      LiveHelpNow:                    () => import('@/components/LiveHelpNow.vue'),
    },
    data() {
      return {
        accounts: null,
        loaded: false,
        lhnWebsites: ['2b918be2-c66c-4aa8-bcce-052677c1f9fa'],
      }
    },
    computed: {
      ...mapGetters('website', ['website']),
      ...mapGetters('session', ['getInitialized']),
      ...mapGetters('account', ['isAccountWholesaler']),
      websiteTosUrl() {
        return this.website.tos_url
      },
      routeLoading() {
        return this.$store.getters['app/getRouteLoading']
      },
      path() {
        return this.$route.path
      },
      needsVirtualPhoneSidebar() {
        return this.path.includes('phones') ||
          this.path.includes('voicemails') ||
          this.path.includes('sms-chat') ||
          this.path.includes('contacts') ||
          (this.path.includes('call') && !this.path.includes('law-on-')) ||
          (this.path.includes('settings') && !this.path.includes('-'))
      },
      account() {
        return this.accounts[0]
      },
      token() {
        return (
          this.$store.state.session.token !== undefined &&
          this.$store.state.session.token != null
        )
      },
      contentFlexContainerClasses() {
        const CssContainer = {
          'hire-us': 'pt-4',
          'stageline': 'pt-4',
          'vehicle-registrations': 'full-width',
          'registrations': 'full-width',
          'documents': 'full-width',
          'invoices': 'full-width',
          'services': 'full-width',
          default: 'd-flex flex-col mw-100',
        }
        const path       = this.$route.path
        const commonPath = Object.keys(CssContainer)
          .filter(key => path.includes(key))
          .sort((a, b) => b.length - a.length)[0]

        return CssContainer[commonPath] || CssContainer.default
      },
      wrapInContentFlexContainer() {
        let path = this.$route.path
        return !path.includes('stageline')
      },
      checkLayout() {
        return this.$mq !== 'lg'
      },
    },
    watch: {
      path() {
        if (this.needsVirtualPhoneSidebar) {
          this.openVirtualPhoneNav()
        }
      },
      getInitialized(oldValue, newValue) {
        if (oldValue.session !== newValue.session) {
          registerStagelineModules(this.website.stageline_version)
          this.loaded = true
        }
      },
    },
    async mounted() {
    },
    methods: {
      openVirtualPhoneNav() {
        this.$refs.mainContent.classList.add('virtual-phone')
      },
    },
  }
</script>

<style lang="scss">
  @import url('https://fonts.googleapis.com/css?family=Roboto');
  .btn-primary:disabled {
    color: #fff;
    background-color: #999;
    border-color: #999;
  }

  .btn-outline-primary:disabled {
    color: #999;
    border-color: #999;

    &:hover {
      color: #999 !important;
      border-color: #999 !important;
      background-color: transparent !important;
    }
  }

  html {
    min-height: 100%;
  }

  html,
  body {
    width: 100% !important;
    height: 100%;
    margin: 0 0 auto !important;
    padding: 0 !important;
    font-family: 'Open Sans', 'Roboto', sans-serif;
    font-weight: 600;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #000;
  }

  #root-flex-container {
    display: flex;
    flex-direction: column;
    margin: 0 0 auto;

    #main {
      min-height: 89vh;
    }
  }

  #root-flex-container .root-flex-row {
    flex: 1;
  }

  .maximize {
    height: 100%;
    min-height: 100%;
  }

  .content-flex-container-top-margin {
    padding-top: 60px;
  }

  .topNav {
    min-height: 50px;
    width: 100%;
  }

  #root-flex-container nav {
    height: 60px !important;
    position: relative;
    top: 0;
    width: 100%!important;
    z-index: 1003;
  }

  #content-flex-container {
    flex: 1 0 50%;
    justify-content: center;
  }

  .full-width {
    width: 100%;
  }

  .full-width > .container {
    max-width: unset;
  }

  .width-75 > .container {
    max-width: 75%;
  }

  #content-flex-container .content-flex-row {
    flex: 1;
  }

  .right-border {
    @extend %default-border;
  }

  .desktop-left-margin {
    margin-left: 275px;
  }

  .desktop-nav-container {
    position: fixed;
    height: calc(100% - 60px);
    width: 275px;
  }

  .desktopNavigation {
    position: fixed;
    height: 100%;
    width: 275px;
    vertical-align: top;
  }

  h1 {
    font-size: 3.5rem;
  }

  .pointer {
    cursor: pointer;
  }

  .max-width {
    max-width: 1040px;
  }

  .modal-backdrop {
    width: 100% !important;
    height: 100% !important;
    opacity: 0.9 !important;
    background: black !important;
  }

  /*Button shadow*/
  .btn-primary,
  .btn-danger {
    box-shadow: 0 1px 2px 0 rgba(50, 50, 93, 0.3),
    0 0 2px 0 rgba(0, 0, 0, 0.2);
    &:disabled {
      box-shadow: none;
    }
  }

  .no-rounded-corners {
    border-radius: 0;
    border: 0 !important;
  }

  /* Align button link to match table */
  .b-table .btn-link {
    padding: 0;
    vertical-align: baseline;
  }

  .toggle-collapse-link-btn-header {
    margin-left: -1em;
    font-size: 1.5rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    display: block;
    margin-left: 0;
    margin-right: 0;
  }

  h1 {
    font-size: 2.25em;
    margin-top: 1.27em;
    margin-bottom: 1.27em;
  }
  h2 {
    font-size: 1.75em;
    margin-top: 1.125em;
    margin-bottom: 1.125em;
  }
  h3 {
    font-size: 1.35em;
    margin-top: 1em;
    margin-bottom: 1em;
  }
  h4 {
    margin-top: 1.33em;
    margin-bottom: 1.33em;
  }
  h5 {
    font-size: 0.83em;
    margin-top: 1.67em;
    margin-bottom: 1.67em;
  }
  h6 {
    font-size: 0.67em;
    margin-top: 2.33em;
    margin-bottom: 2.33em;
  }

  .modal h5 {
    font-size: 1.25em;
    margin-top: 0;
  }

  .was-validated .form-control:valid,
  .form-control.is-valid {
    background-image: none;
  }

  .online-schema-form,
  .pdf-schema-form {
    .custom-select.is-valid {
      background: url( "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
    }
    .is-valid {
      border-color: rgb(206, 212, 218);
      &:focus {
        outline: none;
        border: solid 1px #5879ff;
        box-shadow: 0 0 0 3px #d0daff;
      }
    }
  }

  .left-align-container {
    padding-left: 2%;
  }

  //Table header styling
  .table.b-table > thead > tr > th,
  .table.b-table > thead > tr > td,
  .table.b-table > tfoot > tr > th,
  .table.b-table > tfoot > tr > td {
    border-top: none;
    border-bottom: 2px solid #343a40;
  }

  .table th,
  .table td {
    padding-left: 0;
    padding-right: 0;
  }

  //Table icon
  .table.b-table > thead > tr > th[aria-sort]::before,
  .table.b-table > tfoot > tr > th[aria-sort]::before {
    float: none;
    margin-left: -1em;
    width: 0.5em;
    padding-right: .5rem;
  }

  .text-overflow {
    background: #767676;
    height: 1px;
    vertical-align: middle;
    line-height: 1px;
    margin-top: 2.25rem;
    margin-bottom: 1.5em;
    font-size: 1.4em;
    text-align: center;
    span {
      padding: 10px 20px;
      background: #fff;
    }
  }

  .nav-icon-label {
    color: #fafafa;
    font-style: normal;
    white-space: nowrap;
    span:first-of-type {
      padding-right: 0;
      @media only screen and (min-width: 550px) {
        padding-right: 0.325rem;
      }
    }
    span:last-of-type {
      display: none;
      @media only screen and (min-width: 550px) {
        display: inline-block;
      }
    }
  }

  input {
    font-size: $default-font-size;
    border: 1px solid;
    ::placeholder {
      color: $primary-color;
    }
  }
  :focus {
    outline: none;
    -webkit-appearance: none;
  }
  // ;-)
  .no-borders {
    border: 0;
  }

  .doc-lock-icon-margin {
    .feather-lock {
      margin-top: -3px !important;
    }
  }

  .overrides {
    width: 100%!important;
    min-width: 100% !important;
  }

  @media only screen and (max-width: 769px) {
    h1 {
      font-size: 1.825rem !important;
    }
    #content-flex-container {
      min-height: 400px;
    }
    .mobileNavigation {
      position: fixed;
      bottom: 60px;
    }
  }

  @media only screen and (max-width: 1157px) {
    #content-flex-container {
      margin-bottom: 75px;
    }
  }

  @media only screen and (min-width: 1158px) {
    #content-flex-container {
      flex: 1 0 100%;

      .main.container {
        min-width: 100% !important;
      }
    }
  }

  .page-link {
    border-color: white !important;
  }

  .page-item.active.flex-fill > a {
    color: #000 !important;
    background: white !important;
    border-bottom: 1px black solid !important;
  }

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  }

  /* Used to push the page content to the right when you open the side navigation */
  .main {
    transition: margin-left .5s;
    padding: 20px;
  }

  .nav-spinner-container {
    margin-top: 33vh;
  }

  .tos-footer-container {
    position: relative;
    flex-grow: 1;
    justify-content: center;
  }
  .tos-footer {
    font-size: 0.812em;
    text-align: center;
  }
</style>
