import * as t from '../mutations'
import http from '@/http'
import store from "@/store"


const state = {
  rtcToken: null,
  participantId: null,
  callOnHoldRtcToken: null,
  callOnHoldParticipantId: null,
}

const getters = {
  rtcToken: state => state.rtcToken,
  participantId: state => state.participantId,
  callOnHoldParticipantId: state => state.callOnHoldParticipantId,
}

const actions = {
  async getRtcToken ({ commit }) {
    const virtualPhoneId = await store.getters["virtualPhones/selectedPhone"].id

    const response = await http.get('client/bandwidth/rtc-connection-info', {
      params: {
        virtualPhoneId: virtualPhoneId,
      },
    })
    const token = response.data.response.token
    const participantId = response.data.response['participant_id']


    commit(t.SET_WEB_RTC_TOKEN, token)

    commit(t.SET_PARTICIPANT_ID, participantId)

  },
  setWebRtcToken({ commit }, token ){
    commit(t.SET_WEB_RTC_TOKEN, token)
  },
  setConnectionInfo({ commit }, { token, virtualPhoneId }){
    commit(t.SET_WEB_RTC_TOKEN, token)
    commit(t.SET_VIRTUAL_PHONE_ID, virtualPhoneId)
  },
}

const mutations = {
  [t.SET_WEB_RTC_TOKEN] (state, webRtcToken) {
    state.rtcToken = webRtcToken
  },
  [t.SET_PARTICIPANT_ID] (state, participantId) {
    state.participantId = participantId
  },
  [t.SET_CALL_ON_HOLD_PARTICIPANT_ID] (state, webRtcToken) {
    state.callOnHoldRtcToken = webRtcToken
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
