import * as t from '../mutations'
import http from '@/http'
import { formatPhoneToCountryCodeStyleWithout1 } from '@/common/modules/formatters'

const STATE = {
  currentChatRoom: null,
}

const GETTERS = {
  currentChatRoom: state => state.currentChatRoom,
}

const ACTIONS = {
  async setCurrentChatRoom({ commit, dispatch }, params) {
    const response = await http.get(`client/chat_rooms/chat_room_by_number`, {
      params: {
        phone_number: params['phoneNumber'],
        virtual_phone_id: params['virtualPhoneId'],
      },
    })

    if (response.data){
      commit(t.SET_CHAT_ROOM, response.data)
    } else {
      let room = {
        number: formatPhoneToCountryCodeStyleWithout1(params['phoneNumber']),
        virtualPhoneId: params['virtualPhoneId'],
      }
      await dispatch('sms/createRoom', room, { root: true })
    }
  },
}

const MUTATIONS = {
  [t.SET_CHAT_ROOM](state, chatRoom) {
    state.currentChatRoom = chatRoom
  },
}

export default {
  namespaced: true,
  state: STATE,
  getters: GETTERS,
  actions: ACTIONS,
  mutations: MUTATIONS,
}
