import http from '@/http'
import store from "@/store"
import * as t from "@/store/mutations"


const state = {
  callId: null,
  callRtcStream: null,
  incomingCallId: null,
  isCallAccepted: null,
  callOnHold: {},
}

const getters = {
  callId: state => state.callId,
  callRtcStream: state => state.callRtcStream,
  incomingCallId: state => state.incomingCallId,
  isCallAccepted: state => state.isCallAccepted,
  callOnHold: state => state.callOnHold,
}

const actions = {
  async makeCall({ commit }, { calledNumber, participantId, dialPad }) {
    const virtualPhoneId = await store.getters["virtualPhones/selectedPhone"].id

    const response = await http.post('client/bandwidth/start_call', {
      params: {
        virtualPhoneId: virtualPhoneId,
        calledNumber,
        participantId,
        deviceType: 'web',
      },
    })

    if (response.data.success) {
      commit(t.SET_CALL_ID, response.data.response.callId)
    } else {
      dialPad.$bvToast.toast('An error occurred starting call', {
        title: 'Error',
        variant: 'danger',
        solid: true,
        autoHideDelay: 3000,
      })
    }
  },
  async hangupActiveCall({ commit, getters }, dialPad) {
    const currentCallId = getters.callId || getters.incomingCallId

    const response = await http.post('client/bandwidth/hangup_call', {
      params: { callId: currentCallId },
    })

    if (!response.data.success) {
      dialPad.$bvToast.toast('An error occurred ending call', {
        title: 'Error',
        variant: 'danger',
        solid: true,
        autoHideDelay: 3000,
      })
    }

    commit(t.SET_CALL_ID, null)
  },
  setActiveCall({ commit }, callId) {
    commit(t.SET_CALL_ID, callId)
  },
  async routeToVoicemail({ getters }){
    await http.post('client/bandwidth/redirect_to_voicemail',
      {
        params: { callId: getters.incomingCallId },
      })
  },
  async acceptCall({ dispatch, getters }, dialPad){
    const response = await http.post('client/bandwidth/accept', {
      params: { callId: getters.incomingCallId, deviceType: 'web' },
    })

    if (response.data.success) {
      await dispatch('setIsCallAccepted', true)
    } else {
      dialPad.$bvToast.toast('An error occurred accepting call', {
        title: 'Error',
        variant: 'danger',
        solid: true,
        autoHideDelay: 3000,
      })
    }
  },
  setIncomingCallId({ commit }, incomingCallId ) {
    commit(t.SET_INCOMING_CALL_ID, incomingCallId)
  },
  setIsCallAccepted({ commit }, isCallAccepted) {
    commit(t.SET_IS_CALL_ACCEPTED, isCallAccepted)
  },

  // eslint-disable-next-line no-empty-pattern
  async holdCall({}, callId, dialPad) {
    const response = await http.post('client/bandwidth/hold', {
      params: { callId: callId },
    })

    if (!response.data.success) {
      dialPad.$bvToast.toast('An error occurred while placing call on hold', {
        title: 'Error',
        variant: 'danger',
        solid: true,
        autoHideDelay: 3000,
      })
    }
  },
  setCallRtcStream({ commit }, rtcStream) {
    commit(t.SET_CALL_RTC_STREAM, rtcStream)
  },
  async hangupCallOnHold({ commit }, callId) {
    await http.post('client/bandwidth/hangup_call', {
      params: { callId: callId },
    })
    const emptyObject = {}
    commit(t.SET_CALL_ON_HOLD, emptyObject )
  },

  // eslint-disable-next-line no-empty-pattern
  async resumeCallOnHold({}, callId) {
    await http.post('client/bandwidth/accept', {
      params: { callId: callId },
    })
  },
  setCallOnHold({ commit }, call) {
    commit(t.SET_CALL_ON_HOLD, call)
  },

  // eslint-disable-next-line no-empty-pattern
  async logCall({}, call) {
    return await http.post('client/bandwidth/log_call', {
      params: {
        virtualPhoneId: call.virtualPhoneId,
        direction: call.direction,
        toNumber: call.toNumber,
        fromNumber: call.fromNumber,
        eventType: call.eventType,
      },
    })
  },
}

const mutations = {
  [t.SET_CALL_ID] (state, callId) {
    state.callId = callId
  },
  [t.SET_INCOMING_CALL_ID] (state, incomingCallId) {
    state.incomingCallId = incomingCallId
  },
  [t.SET_IS_CALL_ACCEPTED] (state, isCallAccepted) {
    state.isCallAccepted = isCallAccepted
  },
  [t.SET_CALL_ON_HOLD] (state, call) {
    state.callOnHold = call
  },
  [t.SET_CALL_RTC_STREAM] (state, rtcStream) {
    state.callRtcStream = rtcStream
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
