import * as t from '../mutations'
import http from '@/http'
import { contactNameOrPhoneNumber, setContactsForRooms } from '@/helpers'

const STATE = {
  rooms: [],
  messages: [],
  messagesLoaded: false,
}

const GETTERS = {
  rooms: state => state.rooms,
  messages: state => state.messages,
  messagesLoaded: state => state.messagesLoaded,
}

const ACTIONS = {
  async getRooms({ dispatch }, { id }) {
    const response = await http.get(`client/sms/rooms/${id}`, {
      params: {
        limit: 500,
      },
    })
    dispatch('setRoomContacts', response.data.result.rooms)
  },
  async getMessages({ commit, dispatch, rootGetters }, { chat_room_id }) {
    commit(t.LOAD_MESSAGES_START)
    const response = await http.get(`client/sms/messages/${chat_room_id}`, {
      params: {
        limit: 100,
      },
    })
    commit(t.GET_MESSAGES, response.data.result.map(text => {
      text.username = contactNameOrPhoneNumber(rootGetters['account/people'], text.username.substring(2), text.username)

      // No files present due to purged attachment data.
      if (text.attachments_expired) {
        // Content is still optionally present on the message if the attachment expired
        if (text.content && text.content !== "") {
          text.content = text.content + "<br/>"
        }

        text.content = text.content + "<div class='attachments-expired'>This messages attachment data has expired.</div>"
      } else if (text.files.length >= 1) {
        // Files are present and will expire X days in future
        const currentYear = new Date().getFullYear()
        const dateStr = text.timestamp + ' ' + currentYear
        const dateObj = new Date(dateStr)
        const currentDate = new Date()
        const difference = currentDate - dateObj
        const millisecondsPerDay = 24 * 60 * 60 * 1000 // Number of milliseconds in a day
        const daysDifference = Math.floor(difference / millisecondsPerDay)
        let daysUntilExpiration = rootGetters['virtualPhones/selectedPhone'].media_lifespan_in_days - daysDifference
        daysUntilExpiration = daysUntilExpiration < 0 ? 0 : daysUntilExpiration

        if (text.content && text.content !== "") {
          text.content = text.content + "<br/>"
        }

        text.content = text.content + `<div class='attachments-expired'>This messages attachment(s) expire in ${daysUntilExpiration} days.</div>`
      }

      return text
    }))
    commit(t.LOAD_MESSAGES_FINISHED)
    dispatch('virtualPhones/fetchPhoneNotificationCount', '', { root: true })
  },
  async sendMessage({ commit, state, rootGetters }, { message, files, vm }) {
    try {
      if (files.length) {
        let formData = new FormData()
        files.forEach(f => {
          formData.append('files[]', f.blob, f.blob.type)
        })
        formData.append('message', JSON.stringify(message))

        await http({
          method: "post",
          url: "client/sms/create_outbound_with_attachments",
          data: formData,
          headers: { "Content-Type": "multipart/form-data" },
        }).then(res => {
          commit(t.GET_MESSAGES, [...state.messages, res.data.result].map(text => {
            text.username = contactNameOrPhoneNumber(rootGetters['account/people'], text.username.substring(2), text.username)
            return text
          }))
        })
      } else {
        await http.post(`client/sms/create_outbound`, { message }).then(res => {
          commit(t.GET_MESSAGES, [...state.messages, res.data.result].map(text => {
            text.username = contactNameOrPhoneNumber(rootGetters['account/people'], text.username.substring(2), text.username)
            return text
          }))
        })
      }
    } catch (error) {
      vm.$bvToast.toast(error.response.data.error.message, {
        title: 'Error',
        variant: 'danger',
        solid: true,
        autoHideDelay: 3000,
      })
    }
  },
  async createRoom({ dispatch }, room) {
    await http.post(`client/sms/create_room`, { room } )
    await dispatch('getRooms', { id: room.virtualPhoneId })
  },
  async deleteMessage({ commit, state }, messageId) {
    await http.delete(`client/sms/delete_message/${messageId}`)
    commit(t.GET_MESSAGES, state.messages.filter(each => each._id !== messageId))
  },
  async deleteRoom({ commit, state }, roomId) {
    await http.delete(`client/sms/delete_chat_room/${roomId}`)
    commit(t.GET_ROOMS, state.rooms.filter(each => each.roomId !== roomId))
  },
  async nameRoom({ state }, { roomId, name }) {
    await http.post(`client/sms/name_chat_room/${roomId}`, { room_name: name })

    let roomIndex = state.rooms.findIndex(r => r.roomId === roomId)
    state.rooms[roomIndex].roomName = name
    state.rooms[roomIndex].room_name_override = name
  },
  async muteRoom({ state }, roomId) {
    await http.post(`client/sms/mute_chat_room/${roomId}`)

    let roomIndex = state.rooms.findIndex(r => r.roomId === roomId)
    state.rooms[roomIndex].notifications_muted = true
  },
  async unmuteRoom({ state }, roomId) {
    await http.post(`client/sms/unmute_chat_room/${roomId}`)

    let roomIndex = state.rooms.findIndex(r => r.roomId === roomId)
    state.rooms[roomIndex].notifications_muted = false
  },
  setRoomContacts( { commit, rootGetters }, rooms){
    let people = rootGetters['account/people']
    commit(t.GET_ROOMS, setContactsForRooms(people, rooms))
  },
}

const MUTATIONS = {
  [t.GET_ROOMS](state, rooms) {
    state.rooms = rooms
  },
  [t.LOAD_MESSAGES_START](state) {
    state.messages = []
    state.messagesLoaded = false
  },
  [t.LOAD_MESSAGES_FINISHED](state) {
    state.messagesLoaded = true
  },
  [t.GET_MESSAGES](state, messages) {
    state.messages = messages
  },
}

export default {
  namespaced: true,
  state: STATE,
  getters: GETTERS,
  actions: ACTIONS,
  mutations: MUTATIONS,
}
